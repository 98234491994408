var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"addProductForm"},[_c('b-overlay',{attrs:{"variant":"transparent","blur":"2px","no-wrap":"","show":_vm.show}}),_c('b-form',[_c('b-form-group',{attrs:{"label":"Product Image","label-for":"image"}},[_c('validation-provider',{attrs:{"name":"Product Image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreview),expression:"showPreview"}],staticClass:"mb-2",attrs:{"id":"image-preview","height":"360","width":"360"}}),_c('b-form-file',{attrs:{"id":"image","name":"image","accept":".jpg, .png, .gif, .jpeg","state":errors.length > 0 ? false:null},on:{"change":function($event){return _vm.returnImageFile($event)}},model:{value:(_vm.productForm.imageFile),callback:function ($$v) {_vm.$set(_vm.productForm, "imageFile", $$v)},expression:"productForm.imageFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Product Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Product Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","state":errors.length > 0 ? false:null,"placeholder":"Product name"},model:{value:(_vm.productForm.name),callback:function ($$v) {_vm.$set(_vm.productForm, "name", $$v)},expression:"productForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Product Description","label-for":"desc"}},[_c('validation-provider',{attrs:{"name":"Product Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"desc","name":"desc","state":errors.length > 0 ? false:null,"placeholder":"Product description"},model:{value:(_vm.productForm.desc),callback:function ($$v) {_vm.$set(_vm.productForm, "desc", $$v)},expression:"productForm.desc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex flex-row flex-wrap align-items-start justify-content-between"},[_c('div',{staticClass:"container-fluid col-12 col-sm-6 pr-sm-1 p-0"},[_c('b-form-group',{attrs:{"label":"Price","label-for":"price"}},[_c('validation-provider',{attrs:{"name":"Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","name":"price","type":"number","state":errors.length > 0 ? false:null,"placeholder":"Price"},model:{value:(_vm.productForm.price),callback:function ($$v) {_vm.$set(_vm.productForm, "price", $$v)},expression:"productForm.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"container-fluid col-12 col-sm-6 pl-sm-1 p-0"},[_c('b-form-group',{attrs:{"label":"Reduced Price","label-for":"rrp"}},[_c('validation-provider',{attrs:{"name":"Reduced Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rrp","name":"rrp","type":"number","state":errors.length > 0 ? false:null,"placeholder":"Reduced price"},model:{value:(_vm.productForm.rrp),callback:function ($$v) {_vm.$set(_vm.productForm, "rrp", $$v)},expression:"productForm.rrp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"container-fluid col-12 col-sm-6 pr-sm-1 p-0"},[_c('b-form-group',{attrs:{"label":"Product Quantity","label-for":"quantity"}},[_c('validation-provider',{attrs:{"name":"Product Quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"quantity","name":"quantity","state":errors.length > 0 ? false:null,"placeholder":"Quantity"},model:{value:(_vm.productForm.quantity),callback:function ($$v) {_vm.$set(_vm.productForm, "quantity", $$v)},expression:"productForm.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"container-fluid col-12 col-sm-6 pl-sm-1 p-0"},[_c('b-form-group',{attrs:{"label":"Product Status","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"Product Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"status","name":"status","options":_vm.productStatuses,"state":errors.length > 0 ? false:null},model:{value:(_vm.productForm.status),callback:function ($$v) {_vm.$set(_vm.productForm, "status", $$v)},expression:"productForm.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"d-flex flex-row flex-wrap align-items-center justify-content-end p-0 mt-1 mb-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",modifiers:{"400":true}}],staticClass:"order-1 order-sm-2 col-12 col-sm-4 col-lg-3",attrs:{"variant":"success","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validateForm($event)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Add Product")])],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",modifiers:{"400":true}}],staticClass:"order-2 order-sm-1 col-12 col-sm-2 col-lg-2 mt-1 mt-sm-0 mr-sm-1",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$emit('cancel-add-product')}}},[_vm._v("Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }