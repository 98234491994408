<template>
    <div>
        Admin Dashboard
        <!-- <add-product/> -->
    </div>
</template>

<script>
    import AddProduct from '@/views/forms/admin/AddProduct.vue'
    export default {
        components: {
            AddProduct,
        }
    }
</script>