<template>
    <validation-observer ref="addProductForm">
        <b-overlay variant="transparent" blur="2px" no-wrap :show="show"/>
        <b-form>
            <!-- Product Image -->
            <b-form-group label="Product Image" label-for="image">
                <validation-provider #default="{ errors }" name="Product Image" rules="required">
                    <img id="image-preview" class="mb-2" v-show="showPreview" height="360" width="360"/>
                    <b-form-file id="image" name="image" accept=".jpg, .png, .gif, .jpeg" v-model="productForm.imageFile" :state="errors.length > 0 ? false:null" @change="returnImageFile($event)"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- Product Name -->
            <b-form-group label="Product Name" label-for="name">
                <validation-provider #default="{ errors }" name="Product Name" rules="required">
                    <b-form-input id="name" name="name" v-model="productForm.name" :state="errors.length > 0 ? false:null" placeholder="Product name"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- Product Description -->
            <b-form-group label="Product Description" label-for="desc">
                <validation-provider #default="{ errors }" name="Product Description" rules="required">
                    <b-form-input id="desc" name="desc" v-model="productForm.desc" :state="errors.length > 0 ? false:null" placeholder="Product description"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <div class="d-flex flex-row flex-wrap align-items-start justify-content-between">
                <!-- Product Price -->
                <div class="container-fluid col-12 col-sm-6 pr-sm-1 p-0">
                    <b-form-group label="Price" label-for="price">
                        <validation-provider #default="{ errors }" name="Price" rules="required">
                            <b-form-input id="price" name="price" type="number" v-model="productForm.price" :state="errors.length > 0 ? false:null" placeholder="Price"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </div>

                <!-- Product RRP -->
                <div class="container-fluid col-12 col-sm-6 pl-sm-1 p-0">
                    <b-form-group label="Reduced Price" label-for="rrp">
                        <validation-provider #default="{ errors }" name="Reduced Price">
                            <b-form-input id="rrp" name="rrp" type="number" v-model="productForm.rrp" :state="errors.length > 0 ? false:null" placeholder="Reduced price"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </div>

                <!-- Product Quantity -->
                <div class="container-fluid col-12 col-sm-6 pr-sm-1 p-0">
                    <b-form-group label="Product Quantity" label-for="quantity">
                        <validation-provider #default="{ errors }" name="Product Quantity" rules="required">
                            <b-form-input id="quantity" name="quantity" v-model="productForm.quantity" :state="errors.length > 0 ? false:null" placeholder="Quantity"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </div>

                <!-- Product Status -->
                <div class="container-fluid col-12 col-sm-6 pl-sm-1 p-0">
                    <b-form-group label="Product Status" label-for="status">
                        <validation-provider #default="{ errors }" name="Product Status" rules="required">
                            <b-form-select id="status" name="status" v-model="productForm.status" :options="productStatuses" :state="errors.length > 0 ? false:null"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </div>
            </div>

            <div class="d-flex flex-row flex-wrap align-items-center justify-content-end p-0 mt-1 mb-1">
                <b-button v-ripple.400 variant="success" class="order-1 order-sm-2 col-12 col-sm-4 col-lg-3" type="submit" @click.prevent="validateForm">
                    <feather-icon icon="PlusIcon"/>
                    <span class="align-middle">Add Product</span>
                </b-button>
                <b-button v-ripple.400 variant="outline-secondary" class="order-2 order-sm-1 col-12 col-sm-2 col-lg-2 mt-1 mt-sm-0 mr-sm-1" @click="$emit('cancel-add-product')">Cancel</b-button>
            </div>
        </b-form>
    </validation-observer>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import {
        BRow,
        BCol,
        BLink,
        BButton,
        BForm,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BCardTitle,
        BCardText,
        BFormRadioGroup,
        BFormSelect,
        BFormFile,
        BOverlay,
    } from 'bootstrap-vue'
    import { required, alpha } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
    import Ripple from 'vue-ripple-directive'
    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BButton,
            BForm,
            BFormCheckbox,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BInputGroupAppend,
            BCardTitle,
            BCardText,
            BFormRadioGroup,
            BFormSelect,
            BFormFile,
            BOverlay,
            // Validation
            ValidationObserver,
            ValidationProvider,
            FeatherIcon,
        },
        directives: {
            Ripple
        },
        data() {
            return {
                imagePreview: null,
                showPreview: false,
                productForm: {
                    imageFile: null,
                    image: null,
                    name: null,
                    desc: null,
                    price: null,
                    rrp: null,
                    quantity: null,
                    status: '',
                },
                productStatuses: [],
                required,
                alpha,
                show: false,
            }
        },
        mounted() {
            this.productStatuses = this.$store.getters.getProductStatuses
        },
        computed: {},
        watch: {},
        methods: {
            async returnImageFile(event) {
                await new Promise(resolve => {
                    if (event.target.files.length > 0) {
                        var file = event.target.files[0]
                        var fileReader = new FileReader()
                        
                        fileReader.onload = function(event) {
                            resolve(event.target.result)
                        }
                        fileReader.readAsDataURL(file)
                    }
                    else {
                        resolve(null)
                    }
                }).then(data => {
                    if (data) {
                        this.imagePreview = data
                        this.showPreview = true
                        document.getElementById('image-preview').setAttribute('src', data)
                    }
                    else {
                        this.showPreview = false
                    }
                })
            },
            validateForm() {
                this.show = true
                this.$refs.addProductForm.validate().then(success => {
                    if (success) {
                        console.log(this.productForm)
                        this.uploadPhoto(this.productForm)
                    }
                    else {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Invalid form',
                                text: 'Kindly correct all errors and try again.',
                                icon: 'EditIcon',
                                variant: 'danger'
                            }
                        })
                        this.show = false
                    }
                }).catch(ex => {
                    console.log('Cannot validate addProductForm: ', ex)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Unable to validate form',
                            text: ex,
                            icon: 'EditIcon',
                            variant: 'danger'
                        }
                    })
                    this.show = false
                })
            },
            async uploadPhoto(form) {
                // Check first if a previous tempfile has already been uploaded and recorded locally.
                var temp = this.$store.getters.getTempFile
                if (temp) {
                    // Jump to submitting the form if an existing file has been recorded.
                    delete form.imageFile
                    form.image = temp.toString()
                    this.submitForm(form)
                }
                else {
                    // First, the photo has to be uploaded.
                    // As the endpoint for product addition only accepts a value given by the upload API,
                    // the upload and actual product addition has to be separated.
                    var photoUploadForm = new FormData()
                    photoUploadForm.append('image', form.imageFile, form.imageFile.name)

                    this.$store.dispatch('uploadProductPhoto', photoUploadForm).then(response => {
                        let res = response.data
                        if (res.result) {
                            console.log(res)
                            let tempfile = res.data.file
                            // Delete and rewrite the current image value in the product form with
                            // the response returned by the upload endpoint.
                            delete form.imageFile
                            form.image = tempfile.toString()

                            // Then dispatch the product form.
                            this.submitForm(form)
                        }
                        else {
                            console.log('Error uploading photo: ', res)
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Failed to upload photo',
                                    icon: 'CameraIcon',
                                    variant: 'danger',
                                    text: 'Kindly resubmit the form to try again.'
                                }
                            })
                            this.show = false
                        }
                    }).catch(ex => {
                        console.log('Error dispatching uploadProductPhoto: ', ex)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Unable to dispatch upload photo request',
                                icon: 'CameraIcon',
                                variant: 'danger',
                                text: ex
                            }
                        })
                        this.show = false
                    })
                }
            },
            async submitForm(form) {
                // Then dispatch the product form after.
                this.$store.dispatch('addProduct', form).then(response => {
                    let res = response.data
                    if (res.result) {
                        console.log(res)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Product successfully added',
                                icon: 'ShoppingBagIcon',
                                variant: 'success',
                                text: 'Product may now be viewed and ordered by users.'
                            }
                        })

                        // Emit the cancel-add-product event once successful to swap the contents.
                        this.$emit('cancel-add-product')
                        this.show = false
                    }
                    else {
                        console.log('Error adding product: ', res)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Failed to add product',
                                icon: 'ShoppingBagIcon',
                                variant: 'danger',
                                text: res.message
                            }
                        })
                        this.show = false
                    }
                }).catch(ex => {
                    console.log('Error dispatching addProduct: ', ex)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Unable to dispatch add product request',
                            icon: 'ShoppingBagIcon',
                            variant: 'danger',
                            text: ex
                        }
                    })
                    this.show = false
                })
            }
        }
    }
</script>