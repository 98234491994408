<template>
    <div>
        Hello Orders!
    </div>
</template>

<script>

export default {
    data() {
        return {
            
        }
    },
    created() {
        console.log('Hello orders!')
    },
    methods: {
        
    }
}
</script>