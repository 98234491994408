<template>
  <div>
    <!-- <menu-grid v-if="!isAdmin" :isFromLanding="false"/> -->
    <!-- <orders v-if="!isAdmin"/> -->
    <div v-if="!isAdmin" class="p-5 text-center">
      Wanna do something? Click on an item in the left!
    </div>
    <admin-home v-if="isAdmin"/>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BCard, BCardText, BLink } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import Orders from '@/views/apps/orders/Orders.vue'
import AdminHome from '@/views/apps/admin/AdminHome.vue'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardText,
    BLink,
    BButton,
    Orders,
    AdminHome,
  },
  directives: {
    Ripple
  },
  data() {
    // Variable declarations
    return {
      items: [],
      isAdmin: false,
    }
  },
  created() {
    var permission = useJwt.jwtConfig.getItem()
    if (permission.permission == 1) {
      this.isAdmin = true
    }
    else {
      this.isAdmin = false
    }
  },
  methods: {
      
  },
}
</script>

<style>

</style>
